<template>
  <div class="mb-10 text-center " >
    <a href="./YoyalLimbuCV2023.pdf" download>
      <v-icon large left> mdi-download </v-icon>
    </a>
</div>
</template>
<script>
export default {
  name: "Cv",
};
</script>
